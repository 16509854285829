import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const TabPage = () => (
  <Layout>
    <Header /> 

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0">Table of contents</h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <a href="/clue1" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 1
            </a>
            <br></br> 
            <a href="/clue2" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 2
            </a>
            <br></br> 
            <a href="/clue3" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 3
            </a>
            <br></br> 
            <a href="/clue4" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 4
            </a>
            <br></br> 
            <a href="/clue5" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 5
            </a>
            <br></br> 
            <a href="/clue6" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 6
            </a>
            <br></br> 
            <a href="/clue7" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 7
            </a>
            <br></br> 
            <a href="/clue8" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 8
            </a>
            <br></br> 
            <a href="/clue9" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 9
            </a>
            <br></br> 
            <a href="/clue10" className="font-weight-bold mx-auto mt-2 mb-5">
          clue 10
            </a>
            <br></br> 
        </div>
      </div>
    </header>

    

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
    {/* <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="font-weight-bold text-white mb-4"></h2>
            <p className="text-white-50">
              The center of my world.   
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" />
      </div>
    </section> */}
  </Layout>
);

export default TabPage;
